@font-face {
  font-family: Rockwell;
  src: url("./fonts/Rockwell-Bold.woff") format("woff"),
    url("./fonts/Rockwell-Bold.woff2") format("woff2");
}

@font-face {
  font-family: NotoSansDisplay;
  src: url("./fonts/NotoSansDisplay-Bold.woff") format("woff"),
    url("./fonts/NotoSansDisplay-Bold.woff2") format("woff2");
}

body {
  margin: 0;
  padding: 0;
  font-family: NotoSansDisplay;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  background-color: black;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: white;
}
